// import Video from "../video";
// const DearS = () => {
//   const paths = [
//     baseUrl + "DearS/DearS ep 1 (dub)/DearS ep 1 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
//     "DearSDearS ep 2 (dub)DearS ep 2 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
//   ];
//   function load(url) {
//     console.log(url);
//     //   this.setState({ path: url });
//   }
//   return (
//     <div>
//       <Video path=baseUrl + "DearS/DearS ep 1 (dub)/DearS ep 1 (dub) (720p_30fps_H264-192kbit_AAC).mp4" />
//       <button onClick={load("yo")}>Episode One</button>
//       <Video path="DearS\DearS ep 2 (dub)\DearS ep 2 (dub) (720p_30fps_H264-192kbit_AAC).mp4" />
//       {/* <button onclick={load(paths[1])}>Episode Two</button> */}
//       {/* <h2>{this.state.date.toLocaleTimeString()}.</h2> */}
//     </div>
//   );
// };

// export default DearS;

import React from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";
class DearS extends React.Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "DearS/DearS ep 1 (dub)/DearS ep 1 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep2:
        baseUrl + "DearS/DearS ep 2 (dub)/DearS ep 2 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep3: "",
      s1ep4:
        baseUrl + "DearS/DearS ep 4 (dub)/DearS ep 4 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep5:
        baseUrl + "DearS/DearS ep 5 (dub)/DearS ep 5 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep6:
        baseUrl + "DearS/DearS ep 6 (dub)/DearS ep 6 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep7:
        baseUrl + "DearS/DearS ep 7 (dub)/DearS ep 7 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep8:
        baseUrl + "DearS/DearS ep 8 (dub)/DearS ep 8 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep9:
        baseUrl + "DearS/DearS ep 9 (dub)/DearS ep 9 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep10: "DearS/yt5s.com-DearS ep10 (dub).mp4",
      s1ep11:
        baseUrl + "DearS/DearS ep11 (dub)/DearS ep11 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep12:
        baseUrl + "DearS/DearS ep 12 (dub)/DearS ep 12 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
      s1ep13:
        baseUrl + "DearS/DearS ep 13 (dub)/DearS ep 13 (dub) (720p_30fps_H264-192kbit_AAC).mp4",
    };

    this.load = this.load.bind(this);
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox>
          <Video path={this.state.path} />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default DearS;

//http://localhost:3000/media/dears
