const visitorDetails = [
  {
    id: 9,
    title: "Farscape",
    href: "/media/tv/farscape",
  },
  {
    id: 30,
    title: "Kaguya-Sama: Love Is War",
    href: "/media/kaguya-samaloveiswar",
  },
  {
    id: 37,
    title: "My Next Life as a Villainess",
    href: "/media/mynextlifeasavillainess",
  },
  {
    id: 54,
    title: "Sleepy Princess in the Demon Castle",
    href: "/media/sleepyprincessinthedemoncastle",
  },
  {
    id: 55,
    title: "So I'm a Spider, So What?",
    href: "/media/soimaspidersowhat",
  },
  {
    id: 70,
    title: "Welcome To Demon School",
    href: "/media/welcometodemonschool",
  },
  {
    id: 80,
    title: "The Man Who Killed Hitler and Then the Bigfoot",
    href: "/media/movies/themanwhokilledhitlerandthenthebigfoot",
  }
];

export default visitorDetails;
