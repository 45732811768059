const initialDetails = [
  {
    id: 9,
    title: "Farscape",
    href: "/media/tv/farscape",
  },
  {
    id: 16,
    title: "Arifureta",
    href: "/media/tv/arifureta",
  },
  {
    id: 22,
    title: "As Miss Beelzebub Likes It",
    href: "/media/asmissbeelzebublikesit",
  },
  {
    id: 21,
    title: "DearS",
    href: "/media/dears",
  },
  {
    id: 23,
    title: "Edens Zero",
    href: "/media/edenszero",
  },
  {
    id: 25,
    title: "Gabriel DropOut",
    href: "/media/gabrieldropout",
  },
  {
    id: 26,
    title: "Girlfriend, Girlfriend",
    href: "/media/girlfriendgirlfriend",
  },
  {
    id: 27,
    title: "How NOT To Summon a Demon Lord",
    href: "/media/hownottosummonademonlord",
  },
  {
    id: 28,
    title: "Is it Wrong to Try to Pick Up Girls in a Dungeon?",
    href: "/media/isitwrongtotrytopickupgirlsinadungeon",
  },
  {
    id: 30,
    title: "Kaguya-Sama: Love Is War",
    href: "/media/kaguya-samaloveiswar",
  },
  {
    id: 32,
    title: "Konosuba: God's Blessing on this Wonderful World",
    href: "/media/konosubagodsblessingonthiswonderfulworld",
  },
  {
    id: 34,
    title:
      "Life with an Ordinary Guy who Reincarnated as a Total Fantasy Knockout",
    href: "/media/lifewithanordinaryguywhoreincarnatedasatotalfantasyknockout",
  },
  {
    id: 35,
    title: "Moonlit Fantasy",
    href: "/media/moonlitfantasy",
  },
  {
    id: 36,
    title: "My Dress-Up Darling",
    href: "/media/mydress-updarling",
  },
  {
    id: 37,
    title: "My Next Life as a Villainess",
    href: "/media/mynextlifeasavillainess",
  },
  {
    id: 40,
    title: "No Game No Life",
    href: "/media/nogamenolife",
  },
  {
    id: 50,
    title: "Overlord",
    href: "/media/overlord",
  },
  {
    id: 52,
    title: "Recovery of an MMO Junkie",
    href: "/media/recoveryofanmmojunkie",
  },
  {
    id: 53,
    title: "Re: Zero - Starting",
    href: "/media/rezero",
  },
  {
    id: 54,
    title: "Sleepy Princess in the Demon Castle",
    href: "/media/sleepyprincessinthedemoncastle",
  },
  {
    id: 55,
    title: "So I'm a Spider, So What?",
    href: "/media/soimaspidersowhat",
  },
  {
    id: 60,
    title: "The Helpful Fox Senko-san",
    href: "/media/thehelpfulfoxsenko-san",
  },
  {
    id: 70,
    title: "Welcome To Demon School",
    href: "/media/welcometodemonschool",
  },
];

export default initialDetails;
