import React from "react";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
import VideoPlayer from "./VideoPlayer";
import "videojs-hotkeys";
import "../../components/CSS/spacing.css";
import "../../components/CSS/Video.css"
// import m3 from './dash/output.m3u8';
// import init from './fmp4_from_mkv/init.mp4'
// import Hls from "hls.js";
class Video extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    // this.state = {
    //   videoPath: process.env.REACT_APP_API_PATH + this.props.path,
    // };
    this.playerReference = React.createRef(null);
  }

  // https://stackoverflow.com/questions/65006425/how-to-refresh-video-js-player-for-new-video
  // https://stackoverflow.com/questions/72550481/setstate-doesnt-change-the-source-in-a-video-component-in-react
  componentDidUpdate() {
    // if (this.props.path != ) {
    // console.log(this.videoRef)
    this.videoRef.current?.load();
    // }
    // const video = this.player;
    // const hls = new Hls();
    // const url = process.env.REACT_APP_API_PATH + "hlsOutput";
    // const url = "/output/output.mpd";

    // hls.loadSource(url);
    // hls.attachMedia(video);
    // hls.on(Hls.Events.MANIFEST_PARSED, function () { video.play(); });
  }

  render() {
    // const videoPath = process.env.REACT_APP_API_PATH + "media/Anime/Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E01.mp4";
    // const videoPath = process.env.REACT_APP_API_PATH + "media/Anime/Kaguya-sama Love Is War/Season 1/dash4/output.mpd";
    const videoPath = process.env.REACT_APP_API_PATH + this.props.path;
    // console.log(process.env.REACT_APP_TOKEN)
    // console.log(videoJsOptions);
    // console.log("Video is rendered.");
    // method: "GET",
    // headers: {
    // "Content-Type": "video/mp4",
    // },
    if (typeof this.props.path === "string" && this.props.path !== "") {
      // const video = () => {
      //   return fetch(videoPath, {
      //     mode: "no-cors"
      //   }).then( response => response.json());
      // // console.log(videoPath);
      // }
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        aspectRatio: '4:3',
        playbackRates: [0.25, .5, .75, 1, 1.25, 1.5, 1.75, 2],
        // userActions: { // This is the default user actions for video.js hotkeys. Overriden by plugin.
        // hotkeys: true,
        // }
        plugins: {
          hotkeys: {
            enableModifiersForNumbers: false,
            seekStep: 10,
          }
        },
        responsive: true,
        fluid: false,
        liveui: true,
        sources: [
          {
            src: videoPath,
            // src: process.env.REACT_APP_API_PATH + "output/output.mpd",
            // type: "video/mpeg"
            // type: "application/dash+xml",
            // type: "application/x-mpegURL"
            // type: "video/mp4",
          },
        ],
      };
      return (
        <div>
          <center>
            <VideoPlayer {...videoJsOptions} />
            {/* <video
              ref={player => (this.player = player)}
              autoPlay={true}
            /> */}
            {/* <video
              ref={(node) => (this.videoNode = node)} //this.videoRef
              className="video-js"
              id="video"
              width="840"
              controls
            >
              <source id="source" src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            {/* <video ref={this.videoRef} id="video" width="840" controls>
              <source id="source" src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
          </center>
        </div>
      );
    }
    return <div>No Video Loaded</div>;
  }
}

export default Video;
