import Search from "./Search";
import visitorDetails from "../visitorDetails";
import "./CSS/home.css";

const Home = () => {
  return (
    <div>
      <div className="tc default-color ma0 pa4 min-vh-100">
        <Search details={visitorDetails}>
        </Search>
      </div>
    </div>
  );
};

export default Home;
