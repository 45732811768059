import React from "react";
import Card from "./Card";

function SearchList({ filteredTitles }) {
  const filtered = filteredTitles.map((title) => (
    <Card key={title.id} title={title} />
  ));
  return <div>{filtered}</div>;
}

export default SearchList;
