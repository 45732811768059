import React, { Component } from "react";
import Video from "../../Video/Video";
import VideoBox from "../../Video/VideoBox";
import "../../../components/CSS/spacing.css";

class KaguyaSamaLoveIsWar extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "static/media/Anime/";
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E01.mp4",
      s1ep2:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E02.mp4",
      s1ep3:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E03.mp4",
      s1ep4:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E04.mp4",
      s1ep5:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E05.mp4",
      s1ep6:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E06.mp4",
      s1ep7:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E07.mp4",
      s1ep8:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E08.mp4",
      s1ep9:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E09.mp4",
      s1ep10:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E10.mp4",
      s1ep11:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E11.mp4",
      s1ep12:
        baseUrl + "Kaguya-sama Love Is War/Season 1/mp4/Kaguya-sama - Love is War - S01E12.mp4",
      s2ep1:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E01.mp4",
      s2ep2:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E02.mp4",
      s2ep3:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E03.mp4",
      s2ep4:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E04.mp4",
      s2ep5:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E05.mp4",
      s2ep6:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E06.mp4",
      s2ep7:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E07.mp4",
      s2ep8:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E08.mp4",
      s2ep9:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E09.mp4",
      s2ep10:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E10.mp4",
      s2ep11:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E11.mp4",
      s2ep12:
        baseUrl + "Kaguya-sama Love Is War/Season 2/mp4/Kaguya-sama - Love is War - S02E12.mp4",
      s3ep1:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E01.mp4",
      s3ep2:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E02.mp4",
      s3ep3:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E03.mp4",
      s3ep4:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E04.mp4",
      s3ep5:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E05.mp4",
      s3ep6:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E06.mp4",
      s3ep7:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E07.mp4",
      s3ep8:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E08.mp4",
      s3ep9:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E09.mp4",
      s3ep10:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E10.mp4",
      s3ep11:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E11.mp4",
      s3ep12:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E12.mp4",
      s3ep13:
        baseUrl + "Kaguya-sama Love Is War/Season 3/mp4/Kaguya-sama - Love is War - S03E13.mp4",
    };

    this.load = this.load.bind(this);
  }

  async load(season, episode) {
    const data = await fetch(process.env.REACT_APP_API_PATH + "media/Kaguya-sama: Love is War/seasons/" + season + "/" + episode + "/dash", {
      method: "GET",
      headers: {
      },
    }).then((data) => {
      return data.json()
    });
    this.setState({ path: data });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(1, 1)}>Episode 1</button>
          <button onClick={() => this.load(1, 2)}>Episode 2</button>
          <button onClick={() => this.load(1, 3)}>Episode 3</button>
          <button onClick={() => this.load(1, 4)}>Episode 4</button>
          <button onClick={() => this.load(1, 5)}>Episode 5</button>
          <button onClick={() => this.load(1, 6)}>Episode 6</button>
          <button onClick={() => this.load(1, 7)}>Episode 7</button>
          <button onClick={() => this.load(1, 8)}>Episode 8</button>
          <button onClick={() => this.load(1, 9)}>Episode 9</button>
          <button onClick={() => this.load(1, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(1, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(1, 12)}>
            Episode 12
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(2, 1)}>Episode 1</button>
          <button onClick={() => this.load(2, 2)}>Episode 2</button>
          <button onClick={() => this.load(2, 3)}>Episode 3</button>
          <button onClick={() => this.load(2, 4)}>Episode 4</button>
          <button onClick={() => this.load(2, 5)}>Episode 5</button>
          <button onClick={() => this.load(2, 6)}>Episode 6</button>
          <button onClick={() => this.load(2, 7)}>Episode 7</button>
          <button onClick={() => this.load(2, 8)}>Episode 8</button>
          <button onClick={() => this.load(2, 9)}>Episode 9</button>
          <button onClick={() => this.load(2, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(2, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(2, 12)}>
            Episode 12
          </button>
          <br />
          <b>Season 3</b>
          <br />
          <button onClick={() => this.load(3, 1)}>Episode 1</button>
          <button onClick={() => this.load(3, 2)}>Episode 2</button>
          <button onClick={() => this.load(3, 3)}>Episode 3</button>
          <button onClick={() => this.load(3, 4)}>Episode 4</button>
          <button onClick={() => this.load(3, 5)}>Episode 5</button>
          <button onClick={() => this.load(3, 6)}>Episode 6</button>
          <button onClick={() => this.load(3, 7)}>Episode 7</button>
          <button onClick={() => this.load(3, 8)}>Episode 8</button>
          <button onClick={() => this.load(3, 9)}>Episode 9</button>
          <button onClick={() => this.load(3, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(3, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(3, 12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(3, 13)}>
            Episode 13
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default KaguyaSamaLoveIsWar;
