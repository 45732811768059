import React from "react";
import videojs from "video.js";
import "videojs-hotkeys";
import "video.js/dist/video-js.css";
import "../../components/CSS/Video.css";

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.videoNode = React.createRef();
  }
  // Instantiate a Video.js player when the component mounts
  componentDidMount() {
    if (this.videoNode.current) {
    }
    console.log(this.props)
    this.player = videojs(this.videoNode, this.props, () => {
      this.player.focus()
      videojs.log("onPlayerReady", this);
    });
    // this.videoNode.current?.focus();
  }

  // Dispose the player when the component will unmount
  componentWillUnmount() {

    // console.log("DISPOSED PLAYER");
    // if (this.player) {
    //   this.player.dispose();
    // }
  }
  componentDidUpdate() {
    this.player.focus()
    this.player.src({
      src: this.props.sources[0].src,
      type: this.props.sources[0].type,
      // keySystemOptions: [
      //   {
      //     name: 'com.widevine.alpha',
      //     options: {
      //       serverURL: 'http://m.widevine.com/proxy'
      //     }
      //   }
      // ]
    });
    var tracks = this.player.textTracks();
    console.log(tracks)
    // console.log("Updating");
    // if (this.player) {
    //   this.player.dispose();
    //   console.log("Disposing of player.");
    // }
    // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
    //   videojs.log("onPlayerReady", this);
    // });
    //   this.player.src({ //set the players new source
    //     src: this.props.sources[0].src,
    //     type: 'video/mp4'
    // })
    //   this.player.current?.load();
  }

  // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
  // won't create additional wrapper in the DOM.
  //
  // See: https://github.com/videojs/video.js/pull/3856
  // src={this.props["sources"].src}
  render() {
    //  style={{height: 840}}
    // console.log(this.props);
    // https://blog.logrocket.com/a-comprehensive-guide-to-video-playback-in-react/
    console.log("VideoPlayer src: " + this.props.sources[0].src)
    return (
      <div style={{ maxWidth: 1260 }}>
        <video
          ref={(node) => (this.videoNode = node)}
          src={this.props.sources[0].src}
          className="video-js vjs-16-9"
          autoFocus={true}
        ></video>
      </div>
    );
  }
}
