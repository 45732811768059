import React, { Component } from "react";
import { Video } from "../../../components/Video";
import { VideoBox } from "../../../components/Video";

class WelcomeToDemonSchool extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      s1ep1: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 01.mp4",
      s1ep2: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 02.mp4",
      s1ep3: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 03.mp4",
      s1ep4: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 04.mp4",
      s1ep5: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 05.mp4",
      s1ep6: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 06.mp4",
      s1ep7: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 07.mp4",
      s1ep8: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 08.mp4",
      s1ep9: baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 09.mp4",
      s1ep10:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 10.mp4",
      s1ep11:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 11.mp4",
      s1ep12:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 12.mp4",
      s1ep13:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 13.mp4",
      s1ep14:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 14.mp4",
      s1ep15:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 15.mp4",
      s1ep16:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 16.mp4",
      s1ep17:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 17.mp4",
      s1ep18:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 18.mp4",
      s1ep19:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 19.mp4",
      s1ep20:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 20.mp4",
      s1ep21:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 21.mp4",
      s1ep22:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 22.mp4",
      s1ep23:
        baseUrl + "Welcome to Demon School!/Season 1 - 1080p/mp4/Iruma-kun - 23.mp4",
      s2ep1: baseUrl + "Welcome to Demon School!/Season ",
      s2ep2: baseUrl + "Welcome to Demon School!/Season ",
      s2ep3: baseUrl + "Welcome to Demon School!/Season ",
      s2ep4: baseUrl + "Welcome to Demon School!/Season ",
      s2ep5: baseUrl + "Welcome to Demon School!/Season ",
      s2ep6: baseUrl + "Welcome to Demon School!/Season ",
      s2ep7: baseUrl + "Welcome to Demon School!/Season ",
      s2ep8: baseUrl + "Welcome to Demon School!/Season ",
      s2ep9: baseUrl + "Welcome to Demon School!/Season ",
      s2ep10: baseUrl + "Welcome to Demon School!/Season ",
      s2ep11: baseUrl + "Welcome to Demon School!/Season ",
      s2ep12: baseUrl + "Welcome to Demon School!/Season ",
      s2ep13: baseUrl + "Welcome to Demon School!/Season ",
      s2ep14: baseUrl + "Welcome to Demon School!/Season ",
      s2ep15: baseUrl + "Welcome to Demon School!/Season ",
      s2ep16: baseUrl + "Welcome to Demon School!/Season ",
      s2ep17: baseUrl + "Welcome to Demon School!/Season ",
      s2ep18: baseUrl + "Welcome to Demon School!/Season ",
      s2ep19: baseUrl + "Welcome to Demon School!/Season ",
      s2ep20: baseUrl + "Welcome to Demon School!/Season ",
      s2ep21: baseUrl + "Welcome to Demon School!/Season ",
      s2ep22: baseUrl + "Welcome to Demon School!/Season ",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        {" "}
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(this.state.s1ep14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(this.state.s1ep15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(this.state.s1ep16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(this.state.s1ep17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(this.state.s1ep18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(this.state.s1ep19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(this.state.s1ep20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(this.state.s1ep21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(this.state.s1ep22)}>
            Episode 22
          </button>
          <button onClick={() => this.load(this.state.s1ep23)}>
            Episode 23
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default WelcomeToDemonSchool;
