import React, { Component } from "react";
import Video from "../../Video/Video";
import VideoBox from "../../Video/VideoBox";

class Farscape extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/TV/";
    this.state = {
      path: "",
      s1ep1: baseUrl + "Farscape/mp4/Season 1/Farscape S01E01 Premiere.mp4",
      s1ep2: baseUrl + "Farscape/mp4/Season 1/Farscape S01E02 Exodus from Genesis.mp4",
      s1ep3:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E03 Back and Back and Back to the Future.mp4",
      s1ep4: baseUrl + "Farscape/mp4/Season 1/Farscape S01E04 Throne for a Loss.mp4",
      s1ep5: baseUrl + "Farscape/mp4/Season 1/Farscape S01E05 PK Tech Girl.mp4",
      s1ep6:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E06 Thank God it's Friday, Again..mp4",
      s1ep7: baseUrl + "Farscape/mp4/Season 1/Farscape S01E07 I, E.T..mp4",
      s1ep8:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E08 That Old Black Magic.mp4",
      s1ep9: baseUrl + "Farscape/mp4/Season 1/Farscape S01E09 DNA Mad Scientist.mp4",
      s1ep10:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E10 They've Got a Secret.mp4",
      s1ep11:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E11 Till the Blood Runs Clear.mp4",
      s1ep12: baseUrl + "Farscape/mp4/Season 1/Farscape S01E12 The Flax.mp4",
      s1ep13: baseUrl + "Farscape/mp4/Season 1/Farscape S01E13 Rhapsody in Blue.mp4",
      s1ep14: baseUrl + "Farscape/mp4/Season 1/Farscape S01E14 Jeremiah Crichton.mp4",
      s1ep15: baseUrl + "Farscape/mp4/Season 1/Farscape S01E15 Durka Returns.mp4",
      s1ep16: baseUrl + "Farscape/mp4/Season 1/Farscape S01E16 A Human Reaction.mp4",
      s1ep17:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E17 Through the Looking Glass.mp4",
      s1ep18: baseUrl + "Farscape/mp4/Season 1/Farscape S01E18 A Bug's Life.mp4",
      s1ep19: baseUrl + "Farscape/mp4/Season 1/Farscape S01E19 Nerve (1).mp4",
      s1ep20:
        baseUrl + "Farscape/mp4/Season 1/Farscape S01E20 The Hidden Memory (2).mp4",
      s1ep21: baseUrl + "Farscape/mp4/Season 1/Farscape S01E21 Bone to be Wild.mp4",
      s1ep22: baseUrl + "Farscape/mp4/Season 1/Farscape S01E22 Family Ties.mp4",
      s2ep1: baseUrl + "Farscape/mp4/Season 2/Farscape S02E01 Mind the Baby.mp4",
      s2ep2: baseUrl + "Farscape/mp4/Season 2/Farscape S02E02 Vitas Mortis.mp4",
      s2ep3: baseUrl + "Farscape/mp4/Season 2/Farscape S02E03 Taking the Stone.mp4",
      s2ep4:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E04 Crackers Don't Matter.mp4",
      s2ep5: baseUrl + "Farscape/mp4/Season 2/Farscape S02E05 The Way We Weren't.mp4",
      s2ep6: baseUrl + "Farscape/mp4/Season 2/Farscape S02E06 Picture if You Will.mp4",
      s2ep7: baseUrl + "Farscape/mp4/Season 2/Farscape S02E07 Home on the Remains.mp4",
      s2ep8:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E08 Dream a Little Dream.mp4",
      s2ep9: baseUrl + "Farscape/mp4/Season 2/Farscape S02E09 Out of Their Minds.mp4",
      s2ep10: baseUrl + "Farscape/mp4/Season 2/Farscape S02E10 My Three Crichtons.mp4",
      s2ep11:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E11 Look at the Princess (1) A Kiss Is But a Kiss.mp4",
      s2ep12:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E12 Look at the Princess (2) I Do, I Think.mp4",
      s2ep13:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E13 Look at the Princess (3) The Maltese Crichton.mp4",
      s2ep14: baseUrl + "Farscape/mp4/Season 2/Farscape S02E14 Beware of Dog.mp4",
      s2ep15:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E15 Won't Get Fooled Again.mp4",
      s2ep16: baseUrl + "Farscape/mp4/Season 2/Farscape S02E16 The Locket.mp4",
      s2ep17: baseUrl + "Farscape/mp4/Season 2/Farscape S02E17 The Ugly Truth.mp4",
      s2ep18: baseUrl + "Farscape/mp4/Season 2/Farscape S02E18 A Clockwork Nebari.mp4",
      s2ep19:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E19 Liars, Guns and Money (1) A Not So Simple Plan.mp4",
      s2ep20:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E20 Liars, Guns and Money (2) With Friends Like These....mp4",
      s2ep21:
        baseUrl + "Farscape/mp4/Season 2/Farscape S02E21 Liars, Guns and Money (3) Plan B.mp4",
      s2ep22: baseUrl + "Farscape/mp4/Season 2/Farscape S02E22 Die Me, Dichotomy.mp4",
      s3ep1: baseUrl + "Farscape/mp4/Season 3/Farscape S03E01 Season of Death.mp4",
      s3ep2: baseUrl + "Farscape/mp4/Season 3/Farscape S03E02 Suns and Lovers.mp4",
      s3ep3:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E03 Self Inflicted Wounds (1) Could'a, Would'a, Should'a.mp4",
      s3ep4:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E04 Self Inflicted Wounds (2) Wait for the Wheel.mp4",
      s3ep5:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E05 ...Different Destinations.mp4",
      s3ep6: baseUrl + "Farscape/mp4/Season 3/Farscape S03E06 Eat Me.mp4",
      s3ep7: baseUrl + "Farscape/mp4/Season 3/Farscape S03E07 Thanks for Sharing.mp4",
      s3ep8: baseUrl + "Farscape/mp4/Season 3/Farscape S03E08 Green Eyed Monster.mp4",
      s3ep9: baseUrl + "Farscape/mp4/Season 3/Farscape S03E09 Losing Time.mp4",
      s3ep10: baseUrl + "Farscape/mp4/Season 3/Farscape S03E10 Relativity.mp4",
      s3ep11: baseUrl + "Farscape/mp4/Season 3/Farscape S03E11 Incubator.mp4",
      s3ep12: baseUrl + "Farscape/mp4/Season 3/Farscape S03E12 Meltdown.mp4",
      s3ep13: baseUrl + "Farscape/mp4/Season 3/Farscape S03E13 Scratch 'n Sniff.mp4",
      s3ep14:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E14 Infinite Possibilities (1) Daedalus Demands.mp4",
      s3ep15:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E15 Infinite Possibilities (2) Icarus Abides.mp4",
      s3ep16: baseUrl + "Farscape/mp4/Season 3/Farscape S03E16 Revenging Angel.mp4",
      s3ep17: baseUrl + "Farscape/mp4/Season 3/Farscape S03E17 The Choice.mp4",
      s3ep18: baseUrl + "Farscape/mp4/Season 3/Farscape S03E18 Fractures.mp4",
      s3ep19:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E19 I-Yensch, You-Yensch.mp4",
      s3ep20:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E20 Into the Lion's Den (1) Lambs to the Slaughter.mp4",
      s3ep21:
        baseUrl + "Farscape/mp4/Season 3/Farscape S03E21 Into the Lion's Den (2) Wolf in Sheep's Clothing.mp4",
      s3ep22: baseUrl + "Farscape/mp4/Season 3/Farscape S03E22 Dog with Two Bones.mp4",
      s4ep1: baseUrl + "Farscape/mp4/Season 4/Farscape S04E01 Crichton Kicks.mp4",
      s4ep2:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E02 What Was Lost (1) Sacrifice.mp4",
      s4ep3:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E03 What Was Lost (2) Resurrection.mp4",
      s4ep4:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E04 Lava's a Many Splendored Thing.mp4",
      s4ep5: baseUrl + "Farscape/mp4/Season 4/Farscape S04E05 Promises.mp4",
      s4ep6: baseUrl + "Farscape/mp4/Season 4/Farscape S04E06 Natural Election.mp4",
      s4ep7: baseUrl + "Farscape/mp4/Season 4/Farscape S04E07 John Quixote.mp4",
      s4ep8:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E08 I Shrink Therefore I Am.mp4",
      s4ep9: baseUrl + "Farscape/mp4/Season 4/Farscape S04E09 A Prefect Murder.mp4",
      s4ep10: baseUrl + "Farscape/mp4/Season 4/Farscape S04E10 Coup by Clam.mp4",
      s4ep11:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E11 Unrealized Reality (1).mp4",
      s4ep12: baseUrl + "Farscape/mp4/Season 4/Farscape S04E12 Kansas (2).mp4",
      s4ep13: baseUrl + "Farscape/mp4/Season 4/Farscape S04E13 Terra Firma (3).mp4",
      s4ep14: baseUrl + "Farscape/mp4/Season 4/Farscape S04E14 Twice Shy.mp4",
      s4ep15: baseUrl + "Farscape/mp4/Season 4/Farscape S04E15 Mental as Anything.mp4",
      s4ep16:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E16 Bringing Home the Beacon.mp4",
      s4ep17:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E17 A Constellation of Doubt.mp4",
      s4ep18: baseUrl + "Farscape/mp4/Season 4/Farscape S04E18 Prayer.mp4",
      s4ep19:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E19 We're So Screwed (1) Fetal Attraction.mp4",
      s4ep20:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E20 We're So Screwed (2) Hot to Katratzi.mp4",
      s4ep21:
        baseUrl + "Farscape/mp4/Season 4/Farscape S04E21 We're So Screwed (3) La Bomba.mp4",
      s4ep22: baseUrl + "Farscape/mp4/Season 4/Farscape S04E22 Bad Timing.mp4",
    };
  }

  async load(season, episode) {
    // console.log(process.env.REACT_APP_API_PATH + "media/Farscape/seasons/" + season + "/" + episode + "/dash")
    const data = await fetch(process.env.REACT_APP_API_PATH + "media/Farscape/seasons/" + season + "/" + episode + "/dash", {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*", // https://api.julianprice.site
        // "Access-Control-Allow-Methods": "GET, POST, PUT",
      },
    }).then((data) => {
      return data.json()
    });
    this.setState({ path: data });
    // this.setState({ path: data });
  }
  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(1, 1)}>Episode 1</button>
          <button onClick={() => this.load(1, 2)}>Episode 2</button>
          <button onClick={() => this.load(1, 3)}>Episode 3</button>
          <button onClick={() => this.load(1, 4)}>Episode 4</button>
          <button onClick={() => this.load(1, 5)}>Episode 5</button>
          <button onClick={() => this.load(1, 6)}>Episode 6</button>
          <button onClick={() => this.load(1, 7)}>Episode 7</button>
          <button onClick={() => this.load(1, 8)}>Episode 8</button>
          <button onClick={() => this.load(1, 9)}>Episode 9</button>
          <button onClick={() => this.load(1, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(1, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(1, 12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(1, 13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(1, 14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(1, 15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(1, 16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(1, 17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(1, 18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(1, 19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(1, 20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(1, 21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(1, 22)}>
            Episode 22
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(2, 1)}>Episode 1</button>
          <button onClick={() => this.load(2, 2)}>Episode 2</button>
          <button onClick={() => this.load(2, 3)}>Episode 3</button>
          <button onClick={() => this.load(2, 4)}>Episode 4</button>
          <button onClick={() => this.load(2, 5)}>Episode 5</button>
          <button onClick={() => this.load(2, 6)}>Episode 6</button>
          <button onClick={() => this.load(2, 7)}>Episode 7</button>
          <button onClick={() => this.load(2, 8)}>Episode 8</button>
          <button onClick={() => this.load(2, 9)}>Episode 9</button>
          <button onClick={() => this.load(2, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(2, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(2, 12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(2, 13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(2, 14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(2, 15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(2, 16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(2, 17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(2, 18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(2, 19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(2, 20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(2, 21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(2, 22)}>
            Episode 22
          </button>
          <br />
          <b>Season 3</b>
          <br />
          <button onClick={() => this.load(3, 1)}>Episode 1</button>
          <button onClick={() => this.load(3, 2)}>Episode 2</button>
          <button onClick={() => this.load(3, 3)}>Episode 3</button>
          <button onClick={() => this.load(3, 4)}>Episode 4</button>
          <button onClick={() => this.load(3, 5)}>Episode 5</button>
          <button onClick={() => this.load(3, 6)}>Episode 6</button>
          <button onClick={() => this.load(3, 7)}>Episode 7</button>
          <button onClick={() => this.load(3, 8)}>Episode 8</button>
          <button onClick={() => this.load(3, 9)}>Episode 9</button>
          <button onClick={() => this.load(3, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(3, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(3, 12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(3, 13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(3, 14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(3, 15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(3, 16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(3, 17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(3, 18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(3, 19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(3, 20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(3, 21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(3, 22)}>
            Episode 22
          </button>
          <br />
          <b>Season 4</b>
          <br />
          <button onClick={() => this.load(4, 1)}>Episode 1</button>
          <button onClick={() => this.load(4, 2)}>Episode 2</button>
          <button onClick={() => this.load(4, 3)}>Episode 3</button>
          <button onClick={() => this.load(4, 4)}>Episode 4</button>
          <button onClick={() => this.load(4, 5)}>Episode 5</button>
          <button onClick={() => this.load(4, 6)}>Episode 6</button>
          <button onClick={() => this.load(4, 7)}>Episode 7</button>
          <button onClick={() => this.load(4, 8)}>Episode 8</button>
          <button onClick={() => this.load(4, 9)}>Episode 9</button>
          <button onClick={() => this.load(4, 10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(4, 11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(4, 12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(4, 13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(4, 14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(4, 15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(4, 16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(4, 17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(4, 18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(4, 19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(4, 20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(4, 21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(4, 22)}>
            Episode 22
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default Farscape;
