import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class IsItWrongToTryToPickUpGirlsInADungeon extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {};
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        {" "}
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(this.state.s2ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s2ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s2ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s2ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s2ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s2ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s2ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s2ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s2ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s2ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s2ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s2ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s2ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 3</b>
          <br />
          <button onClick={() => this.load(this.state.s3ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s3ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s3ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s3ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s3ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s3ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s3ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s3ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s3ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s3ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s3ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s3ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s3ep13)}>
            Episode 13
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default IsItWrongToTryToPickUpGirlsInADungeon;
