import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
/** BrowerRouter is used to keep our UI in sync with the URL. Helps with seamless transitions when switching between components.
 * It will only reload/refresh the component that needs to be changed instead of refreshing/reloading the entire page. Not required, but a must for responsive apps.
 */
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
