import React, { Component } from "react";
/* eslint-disable */
class SubSeries extends Component {
  constructor(props) {
    super(props);
  }

  load(url) {
    this.setState({ path: url });
  }

  generateButtons() {
    var buttons = [];
    for (var i = 0; i < this.props.seriesLength; i++) {
      //   console.log(i);
      var stateEpisodePath = "this.state.s1ep" + (i + 1);
      // console.log(stateEpisodePath);
      buttons.push(
        <button key={i + 1} onClick={() => this.props.load(stateEpisodePath)}>
          Episode {i + 1}
        </button>
      );
    }
    return buttons;
  }

  render() {
    return (
      <div>
        {this.generateButtons()}
        {/* <button onClick={() => this.props.load(this.props.s1ep1)}>
          Episode 1
        </button> */}
      </div>
    );
  }
}

export default SubSeries;
