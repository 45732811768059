import React, { Component } from "react";

class MyDressUpDarling extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {};
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return <div></div>;
  }
}

export default MyDressUpDarling;
