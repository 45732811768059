import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class HowNotToSummonADemonLord extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E01.mp4",
      s1ep2:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E02.mp4",
      s1ep3:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E03.mp4",
      s1ep4:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E04.mp4",
      s1ep5:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E05.mp4",
      s1ep6:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E06.mp4",
      s1ep7:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E07.mp4",
      s1ep8:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E08.mp4",
      s1ep9:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E09.mp4",
      s1ep10:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E10.mp4",
      s1ep11:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E11.mp4",
      s1ep12:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S1E12.mp4",
      s2ep1:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E01.mp4",
      s2ep2:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E02.mp4",
      s2ep3:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E03.mp4",
      s2ep4:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E04.mp4",
      s2ep5:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E05.mp4",
      s2ep6:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E06.mp4",
      s2ep7:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E07.mp4",
      s2ep8:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E08.mp4",
      s2ep9:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E09.mp4",
      s2ep10:
        baseUrl + "How NOT to Summon a Demon Lord/Season 1 - 1080p/mp4/How NOT to Summon a Demon Lord - S02E10.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(this.state.s2ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s2ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s2ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s2ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s2ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s2ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s2ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s2ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s2ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s2ep10)}>
            Episode 10
          </button>
          <br />
        </VideoBox>
      </div>
    );
  }
}

export default HowNotToSummonADemonLord;
