import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class AsMissBeelzebubLikesIt extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/"
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E01 - Boy Meets Fluffy Girl.mp4",
      s1ep2:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E02 - Brother Tastes Like A Fairy Tail.mp4",
      s1ep3:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E03 - Former Angel With Wings.mp4",
      s1ep4:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E04 - Fly! Imaginary Wings.mp4",
      s1ep5:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E05 - A Bit Bitter, Bibliomania.mp4",
      s1ep6:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E06 - 9 Centimeters.mp4",
      s1ep7:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E07 - The Pandemonium Baths Are Great.mp4",
      s1ep8:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E08 - I Want To Say Cute.mp4",
      s1ep9:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E09 - They Pass Eachother By Sometimes.mp4",
      s1ep10:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E10 - Separated By One Can Of Coffee.mp4",
      s1ep11:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E11 - Gocchin In Merryland.mp4",
      s1ep12:
        baseUrl + "As Miss Beelzebub Likes It/Season 1/mp4/S1E12 - Her Assistant Knows Not Her Higgness's Heart.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default AsMissBeelzebubLikesIt;
