import React from "react";
// We use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
// eslint-disable-next-line
import Stylesheet from "./components/Stylesheet";
import {
  Arifureta,
  AsMissBeelzebubLikesIt,
  DearS,
  EdensZero,
  GabrielDropout,
  GirlfriendGirlfriend,
  HowNotToSummonADemonLord,
  IsItWrongToTryToPickUpGirlsInADungeon,
  KaguyaSamaLoveIsWar,
  Konosuba,
  LifeWithAnOrdinaryGuyWhoReincarnatedIntoATotalFantasyKnockout,
  MoonlitFantasy,
  MyDressUpDarling,
  MyNextLifeAsAVillainess,
  NoGameNoLife,
  Overlord,
  RecoveryOfAnMMOJunkie,
  ReZero,
  SleepyPrincessInTheDemonCastle,
  SoImASpiderSoWhat,
  TheHelpfulFoxSenkoSan,
  WelcomeToDemonSchool,
} from "./components/media_pages/anime";
import TheManWhoKilledHitlerAndThenTheBigfoot from "./components/media_pages/movies/TheManWhoKilledHitlerAndThenTheBigfoot";
// import Search from "./components/search";
// import initialDetails from "./initialDetails";

// We import all the components we need in our app
import Home from "./components/Home";
import RecordList from "./components/recordList";
import Edit from "./components/edit";
import Create from "./components/create";
import { Farscape } from "./components/media_pages/tv";
import Login from "./components/login/Login";
import useToken from "./components/useToken";
import useVisitor from "./components/useVisitor"
import Visitors from "./components/Visitors";

const App = () => {
  const { token, setToken } = useToken();
  const { visitor, setVisitor } = useVisitor();

  if (!token) {
    return <Login setToken={setToken} setVisitor={setVisitor} />;
  }

  if (visitor) {
    return (
      <div>
        <Stylesheet primary={false} />
        <center style={{ backgroundColor: "darkblue" }}>
          <a
            className="tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5"
            style={{
              textDecoration: "none",
              color: "darkblue",
              fontSize: "25px",
              backgroundColor: "orange",
            }}
            href="/"
          >
            Home
          </a>
        </center>
        {/* <div className="tc bg-green ma0 pa4 min-vh-100">
          <Search details={initialDetails} />
        </div> */}
        <Routes>
          <Route exact path="/" element={<Visitors />} />
          <Route exact path="/database" element={<RecordList />} />
          <Route path="/edit/:id" element={<Edit />} />
          <Route path="/create" element={<Create />} />
          <Route path="/media/tv/farscape" element={<Farscape />} />
          <Route path="/media/arifureta" element={<Arifureta />} />
          <Route
            path="/media/asmissbeelzebublikesit"
            element={<AsMissBeelzebubLikesIt />}
          />
          <Route path="/media/dears" element={<DearS />} />
          <Route path="/media/edenszero" element={<EdensZero />} />
          <Route path="/media/gabrieldropout" element={<GabrielDropout />} />
          <Route
            path="/media/girlfriendgirlfriend"
            element={<GirlfriendGirlfriend />}
          />
          <Route
            path="/media/hownottosummonademonlord"
            element={<HowNotToSummonADemonLord />}
          />
          <Route
            path="/media/isitwrongtotrytopickupgirlsinadungeon"
            element={<IsItWrongToTryToPickUpGirlsInADungeon />}
          />
          <Route
            path="/media/kaguya-samaloveiswar"
            element={<KaguyaSamaLoveIsWar />}
          />
          <Route
            path="/media/konosubagodsblessingonthiswonderfulworld"
            element={<Konosuba />}
          />
          <Route
            path="/media/lifewithanordinaryguywhoreincarnatedintoatotalfantasyknockout"
            element={
              <LifeWithAnOrdinaryGuyWhoReincarnatedIntoATotalFantasyKnockout />
            }
          />
          <Route path="/media/moonlitfantasy" element={<MoonlitFantasy />} />
          <Route path="/media/mydressupdarling" element={<MyDressUpDarling />} />
          <Route
            path="/media/mynextlifeasavillainess"
            element={<MyNextLifeAsAVillainess />}
          />
          <Route path="/media/nogamenolife" element={<NoGameNoLife />} />
          <Route path="/media/overlord" element={<Overlord />} />
          <Route
            path="/media/recoveryofanmmojunkie"
            element={<RecoveryOfAnMMOJunkie />}
          />
          <Route path="/media/rezero" element={<ReZero />} />
          <Route
            path="/media/sleepyprincessinthedemoncastle"
            element={<SleepyPrincessInTheDemonCastle />}
          />
          <Route
            path="/media/soimaspidersowhat"
            element={<SoImASpiderSoWhat />}
          />
          <Route
            path="/media/thehelpfulfoxsenko-san"
            element={<TheHelpfulFoxSenkoSan />}
          />
          <Route
            path="/media/welcometodemonschool"
            element={<WelcomeToDemonSchool />}
          />
          <Route
            path="/media/movies/themanwhokilledhitlerandthenthebigfoot"
            element={<TheManWhoKilledHitlerAndThenTheBigfoot />}
          />
        </Routes>
      </div>
    );
  }

  return (
    <div>
      <Stylesheet primary={false} />
      <center style={{ backgroundColor: "darkblue" }}>
        <a
          className="tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5"
          style={{
            textDecoration: "none",
            color: "darkblue",
            fontSize: "25px",
            backgroundColor: "orange",
          }}
          href="/"
        >
          Home
        </a>
      </center>
      {/* <div className="tc bg-green ma0 pa4 min-vh-100">
        <Search details={initialDetails} />
      </div> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/database" element={<RecordList />} />
        <Route path="/edit/:id" element={<Edit />} />
        <Route path="/create" element={<Create />} />
        <Route path="/media/tv/farscape" element={<Farscape />} />
        <Route path="/media/arifureta" element={<Arifureta />} />
        <Route
          path="/media/asmissbeelzebublikesit"
          element={<AsMissBeelzebubLikesIt />}
        />
        <Route path="/media/dears" element={<DearS />} />
        <Route path="/media/edenszero" element={<EdensZero />} />
        <Route path="/media/gabrieldropout" element={<GabrielDropout />} />
        <Route
          path="/media/girlfriendgirlfriend"
          element={<GirlfriendGirlfriend />}
        />
        <Route
          path="/media/hownottosummonademonlord"
          element={<HowNotToSummonADemonLord />}
        />
        <Route
          path="/media/isitwrongtotrytopickupgirlsinadungeon"
          element={<IsItWrongToTryToPickUpGirlsInADungeon />}
        />
        <Route
          path="/media/kaguya-samaloveiswar"
          element={<KaguyaSamaLoveIsWar />}
        />
        <Route
          path="/media/konosubagodsblessingonthiswonderfulworld"
          element={<Konosuba />}
        />
        <Route
          path="/media/lifewithanordinaryguywhoreincarnatedintoatotalfantasyknockout"
          element={
            <LifeWithAnOrdinaryGuyWhoReincarnatedIntoATotalFantasyKnockout />
          }
        />
        <Route path="/media/moonlitfantasy" element={<MoonlitFantasy />} />
        <Route path="/media/mydressupdarling" element={<MyDressUpDarling />} />
        <Route
          path="/media/mynextlifeasavillainess"
          element={<MyNextLifeAsAVillainess />}
        />
        <Route path="/media/nogamenolife" element={<NoGameNoLife />} />
        <Route path="/media/overlord" element={<Overlord />} />
        <Route
          path="/media/recoveryofanmmojunkie"
          element={<RecoveryOfAnMMOJunkie />}
        />
        <Route path="/media/rezero" element={<ReZero />} />
        <Route
          path="/media/sleepyprincessinthedemoncastle"
          element={<SleepyPrincessInTheDemonCastle />}
        />
        <Route
          path="/media/soimaspidersowhat"
          element={<SoImASpiderSoWhat />}
        />
        <Route
          path="/media/thehelpfulfoxsenko-san"
          element={<TheHelpfulFoxSenkoSan />}
        />
        <Route
          path="/media/welcometodemonschool"
          element={<WelcomeToDemonSchool />}
        />
      </Routes>
    </div>
  );
};

export default App;
