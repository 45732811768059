import React from "react";

function Card({ title }) {
  return (
    <div>
      {/* <img
        className="br-100 h3 w3 dib"
        alt={title.title}
        src={title.title}
        // src={process.env.PUBLIC_URL + person.imgPath}
      /> */}
      <div>
        <a
          className="tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5"
          style={{
            textDecoration: "none",
            color: "darkblue",
            fontSize: "25px",
          }}
          href={title.href}
        >
          {title.title}
        </a>
      </div>
    </div>
  );
}

export default Card;
