import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class SoImASpiderSoWhat extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E01 - Reincarnation, in Another World.mp4",
      s1ep2:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E02 - My House, On Fire.mp4",
      s1ep3:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E03 - Earth Wyrm (Dragon), Bad News.mp4",
      s1ep4:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E04 - Monkey, Wha—.mp4",
      s1ep5:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E05 - Does Catfish, Taste Good.mp4",
      s1ep6:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E06 - The Hero, and the Demon Lord.mp4",
      s1ep7:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E07 - Springtime, for Princes.mp4",
      s1ep8:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E08 - Am I, Dead.mp4",
      s1ep9:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E09 - I Can't Speak, Isekai.mp4",
      s1ep10:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E10 - Who Is This, Geezer.mp4",
      s1ep11:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E11 - Next Time, Is the Big Battle.mp4",
      s1ep12:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E12 - My Battle Has, Only Just Begun.mp4",
      s1ep13:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E13 - Yay, The Outside, I'm Free.mp4",
      s1ep14:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E14 - You're Rebelling, I'm, Self - Deprecating.mp4",
      s1ep15:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E15 - Did Mother, Send These Annoying Puppet Spiders.mp4",
      s1ep16:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E16 - Am I Getting Ahead Of Myself.mp4",
      s1ep17:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E17 - What am I doing.mp4",
      s1ep18:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E18 - You Guys Are Kind of Awful, Huh.mp4",
      s1ep19:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E19 - I Guess, This Is a Class Reunion.mp4",
      s1ep20:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E20 - This Isn't My Fault, Is It.mp4",
      s1ep21:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E21 - So I'm Not in This One, Am I.mp4",
      s1ep22:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E22 - Be Forever, Me.mp4",
      s1ep23:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E23 - My Old Friend, Why .......mp4",
      s1ep24:
        baseUrl + "So I'm a Spider, So What/So I'm a Spider, So What (Kumo Desu ga, Nanika) (Season 1)/mp4/S01E24 - So I'm Still a Spider, So What.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <button onClick={() => this.load(this.state.s1ep14)}>
            Episode 14
          </button>
          <button onClick={() => this.load(this.state.s1ep15)}>
            Episode 15
          </button>
          <button onClick={() => this.load(this.state.s1ep16)}>
            Episode 16
          </button>
          <button onClick={() => this.load(this.state.s1ep17)}>
            Episode 17
          </button>
          <button onClick={() => this.load(this.state.s1ep18)}>
            Episode 18
          </button>
          <button onClick={() => this.load(this.state.s1ep19)}>
            Episode 19
          </button>
          <button onClick={() => this.load(this.state.s1ep20)}>
            Episode 20
          </button>
          <button onClick={() => this.load(this.state.s1ep21)}>
            Episode 21
          </button>
          <button onClick={() => this.load(this.state.s1ep22)}>
            Episode 22
          </button>
          <button onClick={() => this.load(this.state.s1ep23)}>
            Episode 23
          </button>
          <button onClick={() => this.load(this.state.s1ep24)}>
            Episode 24
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default SoImASpiderSoWhat;
