import React from "react";
import "./CSS/myStyles.css";
// import "https://unpkg.com/tachyons/css/tachyons.min.css";

const Stylesheet = (props) => {
  // eslint-disable-next-line
  let className = props.primary ? "primary" : "";
  return (
    <div>{/* <h1 className={`${className} font-xl`}>Stylesheet</h1> */}</div>
  );
};

export default Stylesheet;
// created with rfc TAB
