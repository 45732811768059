import Search from "./Search";
import initialDetails from "../initialDetails";
import "./CSS/home.css";

const Home = () => {
  return (
    <div>
      <div className="tc default-color ma0 pa4 min-vh-100">
        <Search details={initialDetails}>
          {/* <a href="/media/tv/farscape">rrr</a>
          <a href="/media/dears">DearS</a>
          <br />
          <a href="/media/konosuba">Konosuba</a>
          <br />
          <a href="/media/welcometodemonschool">Welcome To Demon School</a>
          <br />
          <a href="/media/asmissbeelzebublikesit">As Miss Beelzebub Likes It</a>
          <br />
          <a href="/media/edenszero">Edens Zero</a>
          <br />
          <br />
          <a href="/media/thehelpfulfoxsenksan">The Helpful Fox Senko-san</a>
          <br />
          <a href="/media/gabrieldropout">Gabriel Dropout</a>
          <br />
          <a href="/media/girlfriendgirlfriend">Girlfriend, Girlfriend</a>
          <br />
          <a href="/media/hownottosummonademonlord">
            How NOT To Summon a Demon Lord
          </a>
          <br />
          <a href="/media/isitwrongtotrytopickupgirlsinadungeon">
            Is it Wrong to Try to Pick Up Girls in a Dungeon?
          </a>
          <br />
          <a href="/media/kaguya-samaloveiswar">Kaguya-Sama: Love Is War</a>
          <br />
          <a href="/media/lifewithanordinaryguywhoreincarnatedintoatotalfantasyknockout"></a>
          <br />
          <a href="/media/mydress-updarling">My Dress-Up Darling</a>
          <br />
          <a href="/media/mynextlifeasavillainess"></a>
          <br />
          <a href="/media/nogamenolife">No Game No Life</a>
          <br />
          <a href="/media/overlord">Overlord</a>
          <br />
          <a href="/media/rezero">Re: Zero - Starting Life in Another World</a>
          <br />
          <a href="/media/sleepyprincessinthedemoncastle">
            Sleepy Princess in the Demon Castle
          </a>
          <br />
          <a href="/media/soimaspidersowhat">So I'm a Spider, So What?</a>
          <br />
          <a href="/media/recoveryofanmmojunkie">Recovery of an MMO Junkie</a>
          <br />
          <a href="/media/arifureta">
            Arifureta: From Commonplace to the World's Strongest
          </a> */}
        </Search>
      </div>
    </div>
  );
};

export default Home;
