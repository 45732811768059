import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class MoonlitFantasy extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1: baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E01 - Failed... Hero.mp4",
      s1ep2:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E02 - Black Spider of Disaster.mp4",
      s1ep3: baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E03 - Human Shock.mp4",
      s1ep4: baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E04 - Too Late.mp4",
      s1ep5:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E05 - The Greedy Wagon's Journey.mp4",
      s1ep6:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E06 - The Melancholy of Handsome Middle-Aged Men.mp4",
      s1ep7:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E07 - Wondrous Medicine Production.mp4",
      s1ep8:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E08 - Demiplane Ranking.mp4",
      s1ep9:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E09 - Eat or Be Eaten.mp4",
      s1ep10:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E10 - Hidden Ogre Village.mp4",
      s1ep11: baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E11 - Goodbye.mp4",
      s1ep12:
        baseUrl + "Moonlit Fantasy/Season 1 - 1080p/mp4/S01E12 - Guided by the Moon....mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }
  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default MoonlitFantasy;
