import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

import "../../../components/CSS/spacing.css";

class Overlord extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E01.mp4",
      s1ep2: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E02.mp4",
      s1ep3: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E03.mp4",
      s1ep4: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E04.mp4",
      s1ep5: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E05.mp4",
      s1ep6: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E06.mp4",
      s1ep7: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E07.mp4",
      s1ep8: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E08.mp4",
      s1ep9: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E09.mp4",
      s1ep10: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E10.mp4",
      s1ep11: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E11.mp4",
      s1ep12: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E12.mp4",
      s1ep13: baseUrl + "Overlord/S1-S3/Overlord - S1/mp4/Overlord - S01E13.mp4",
      s2ep1: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E01.mp4",
      s2ep2: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E02.mp4",
      s2ep3: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E03.mp4",
      s2ep4: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E04.mp4",
      s2ep5: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E05.mp4",
      s2ep6: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E06.mp4",
      s2ep7: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E07.mp4",
      s2ep8: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E08.mp4",
      s2ep9: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E09.mp4",
      s2ep10: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E10.mp4",
      s2ep11: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E11.mp4",
      s2ep12: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E12.mp4",
      s2ep13: baseUrl + "Overlord/S1-S3/Overlord - S2/mp4/Overlord - S02E13.mp4",
      s3ep1: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E01.mp4",
      s3ep2: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E02.mp4",
      s3ep3: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E03.mp4",
      s3ep4: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E04.mp4",
      s3ep5: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E05.mp4",
      s3ep6: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E06.mp4",
      s3ep7: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E07.mp4",
      s3ep8: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E8.mp4",
      s3ep9: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E9.mp4",
      s3ep10: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E10.mp4",
      s3ep11: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E11.mp4",
      s3ep12: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E12.mp4",
      s3ep13: baseUrl + "Overlord/S1-S3/Overlord - S3/mp4/Overlord - S03E13.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <Video path={this.state.path} />
        <VideoBox className="videoBox">
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(this.state.s2ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s2ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s2ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s2ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s2ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s2ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s2ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s2ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s2ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s2ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s2ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s2ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s2ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 3</b>
          <br />
          <button onClick={() => this.load(this.state.s3ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s3ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s3ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s3ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s3ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s3ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s3ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s3ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s3ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s3ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s3ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s3ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s3ep13)}>
            Episode 13
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default Overlord;
