import React, { Component } from "react";
// import episode'1 from "./Konosuba God's Blessing On This Wonderful World! - S01E01 - This Self-Proclaimed Goddess And Reincarnation In Another World!.mp4";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class Konosuba extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: baseUrl + "",
      s1ep1:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E01 - This Self-Proclaimed Goddess And Reincarnation In Another World!.mp4",
      s1ep2:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E02 - An Explosion For This Chuunibyou!.mp4",
      s1ep3:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E03 - A Panty Treasure In This Right Hand!.mp4",
      s1ep4:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E04 - Explosion Magic For This Formidable Enemy!.mp4",
      s1ep5:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E05 - A Price For This Cursed Sword!.mp4",
      s1ep6:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E06 - A Conclusion To This Worthless Fight!.mp4",
      s1ep7:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E07 - A Second Death In This Freezing Season!.mp4",
      s1ep8:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E08 - A Loving Hand For Our Party When We Can't Make It Through Winter!.mp4",
      s1ep9:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E09 - God's Blessing On This Wonderful Shop!.mp4",
      s1ep10:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S01E10 - Final Flame For This Over-The-Top Fortress!.mp4",
      s1OVA:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 1 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! OVA.mp4",
      s2ep1:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E01 - Give Me Deliverance From This Judicial Injustice!.mp4",
      s2ep2:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E02 - A Friend For This Crimson Demon Girl!.mp4",
      s2ep3:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E03 - Peace For The Master Of This Labyrinth!.mp4",
      s2ep4:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E04 - A Betrothed For This Noble Daughter!.mp4",
      s2ep5:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E05 - Servitude For This Masked Knight!.mp4",
      s2ep6:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E06 - Goodbye To This Irritating Living World!.mp4",
      s2ep7:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E07 - An Invitation For This Knucklehead!.mp4",
      s2ep8:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E08 - Sightseeing In This Pitiful City!.mp4",
      s2ep9:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E09 - A Goddess For This Corrupt Hot Springs Town!.mp4",
      s2ep10:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! - S02E10 - God's Blessing On This Wonderful Party!.mp4",
      s2OVA:
        baseUrl + "Konosuba - God's Blessing On This Wonderful World/Season 2 - 1080p/mp4/Konosuba God's Blessing On This Wonderful World! OVA A Blessing To This Wonderful Work Of Art!.mp4",
      s3ep1: baseUrl + "",
      s3ep2: baseUrl + "",
      s3ep3: baseUrl + "",
      s3ep4: baseUrl + "",
      s3ep5: baseUrl + "",
      s3ep6: baseUrl + "",
      s3ep7: baseUrl + "",
      s3ep8: baseUrl + "",
      s3ep9: baseUrl + "",
      s3ep10: baseUrl + "",
      s3ep11: baseUrl + "",
      s3ep12: baseUrl + "",
      s3ep13: baseUrl + "",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s1ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 2</b>
          <br />
          <button onClick={() => this.load(this.state.s2ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s2ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s2ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s2ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s2ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s2ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s2ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s2ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s2ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s2ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s2ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s2ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s2ep13)}>
            Episode 13
          </button>
          <br />
          <b>Season 3</b>
          <br />
          <button onClick={() => this.load(this.state.s3ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s3ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s3ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s3ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s3ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s3ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s3ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s3ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s3ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s3ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s3ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s3ep12)}>
            Episode 12
          </button>
          <button onClick={() => this.load(this.state.s3ep13)}>
            Episode 13
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default Konosuba;
