import React, { Component } from "react";
import "../../components/CSS/spacing.css";

class VideoBox extends Component {
  render() {
    // https://stackoverflow.com/questions/45472455/is-there-any-possibility-to-pass-text-between-react-component-tags
    return <div className="videoBox">{this.props.children}</div>;
    // return <div {...props}>{children}</div>;
    // return <div className={videoBox}></div>;
  }
}

export default VideoBox;
