import React from "react";
// eslint-disable-next-line
import Scroll from "./scroll";
import SearchList from "./searchList";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: "",
      filteredTitles: this.initialFilteredTitles(),
      test: "testing yo",
      searchList: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.searchList = this.searchList.bind(this);
    // this.filteredTitles();
    // this.aNames();
  }

  initialFilteredTitles() {
    return this.props.details.filter((title) => {
      return title.title.toLowerCase();
    });
  }

  filteredTitles() {
    const filtered = this.props.details.filter((title) => {
      return title.title
        .toLowerCase()
        .includes(this.state.searchField.toLowerCase());
    });
    // this.state.filteredTitles = filtered;
    this.setState({ filteredTitles: filtered });
  }

  handleChange = (e) => {
    this.setState({ searchField: e.target.value });
    if (e.target.value === "")
      return this.setState({ filteredTitles: this.initialFilteredTitles() });
    this.filteredTitles();
  };

  searchList() {
    return (
      // <Scroll>
      <SearchList filteredTitles={this.state.filteredTitles} />
      // </Scroll>
    );
  }

  //   aNames() {
  //     const subRows = [];
  //     React.Children.forEach(this.props.children, (child, i) => {
  //       if (child.type !== "a") return;
  //       if (child.props.children) {
  //         subRows.push(child);
  //       }
  //     });
  //     const aValues = subRows.forEach((aValue) => {
  //       return aValue.props.children;
  //     });
  //     console.log(subRows);
  //   }

  render() {
    return (
      <section className="garamond">
        <div className="navy georgia ma0 grow">
          <h2 className="f2">Search media</h2>
        </div>
        <div className="pa2">
          <input
            className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
            type="search"
            placeholder="Search Media"
            onChange={this.handleChange}
          />
        </div>
        {this.searchList()}
      </section>
    );
  }
}

// function Search({ details }, props) {
//   //   console.log(props.children);
//   const subRows = React.Children.toArray(props.children).filter((child, i) => {
//     return child.props.someProp;
//   });
//   console.log(subRows);
//   const [searchField, setSearchField] = useState("");

//   const filteredTitles = details.filter((title) => {
//     return title.title.toLowerCase().includes(searchField.toLowerCase());
//   });
//   //   console.log(filteredTitles);
//   const handleChange = (e) => {
//     setSearchField(e.target.value);
//   };

//   function searchList() {
//     // console.log(filteredTitles);
//     return (
//       <Scroll>
//         <SearchList filteredTitles={filteredTitles} />
//       </Scroll>
//     );
//   }

//   return (
//     <section className="garamond">
//       <div className="navy georgia ma0 grow">
//         <h2 className="f2">Search your course</h2>
//       </div>
//       <div className="pa2">
//         <input
//           className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
//           type="search"
//           placeholder="Search People"
//           onChange={handleChange}
//         />
//       </div>
//       {searchList()}
//     </section>
//   );
// }

export default Search;
