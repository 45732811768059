import React, { Component } from "react";
import Video from "../../Video/Video";
import VideoBox from "../../Video/VideoBox";

class TheManWhoKilledHitlerAndThenTheBigfoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: ""
    }
  }

  async load(movie) {
    // console.log(process.env.REACT_APP_API_PATH + "media/Farscape/seasons/" + season + "/" + episode + "/dash")
    const data = await fetch(process.env.REACT_APP_API_PATH + "media/movies/The Man Who Killed Hitler and Then the Bigfoot/dash", {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*", // https://api.julianprice.site
        // "Access-Control-Allow-Methods": "GET, POST, PUT",
      },
    }).then((data) => {
      return data.json()
    });
    this.setState({ path: data });
    // this.setState({ path: data });
  }
  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>The Man Who Killed Hitler and Then the Bigfoot</b>
          <br />
          <button onClick={() => this.load("TheManWhoKilledHitlerAndThenTheBigfoot")}>Play</button>
        </VideoBox>
      </div>
    );
  }
}

export default TheManWhoKilledHitlerAndThenTheBigfoot;
