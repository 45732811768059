import React, { Component } from "react";
import { Video } from "../../Video";
import { VideoBox } from "../../Video";

class GirlfriendGirlfriend extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.state = {
      path: "",
      s1ep1:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E01.mp4",
      s1ep2:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E02.mp4",
      s1ep3:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E03.mp4",
      s1ep4:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E04.mp4",
      s1ep5:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E05.mp4",
      s1ep6:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E06.mp4",
      s1ep7:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E07.mp4",
      s1ep8:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E08.mp4",
      s1ep9:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E09.mp4",
      s1ep10:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E10.mp4",
      s1ep11:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E11.mp4",
      s1ep12:
        baseUrl + "Girlfriend, Girlfriend/Season 1/mp4/Girlfriend, Girlfriend - S01E12.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    return (
      <div>
        <VideoBox className="videoBox">
          <Video path={this.state.path} />
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default GirlfriendGirlfriend;
