import React, { useState } from "react";
import PropTypes from "prop-types";

import "../CSS/Login.css";
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

// Note: In a full application, you’ll need to handle situations where the component unmounts before a Promise resolves.
// https://www.digitalocean.com/community/tutorials/how-to-call-web-apis-with-the-useeffect-hook-in-react
async function loginUser(credentials) {
  console.log(process.env.REACT_APP_LOGIN_URL);
  return await fetch(process.env.REACT_APP_LOGIN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://api.julianprice.site", // https://api.julianprice.site
      // "Access-Control-Allow-Methods": "GET, POST, PUT",
      // "Access-Control-Allow-Headers": "Content-Type"
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
  // TODO - Add the below .catch method. Token is set to undefined when it's added, so need to fix that.
  // .catch(error => { 
  // console.log("Something went wrong logging in.")
  // });
}

export default function Login({ setToken, setVisitor }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password,
    });
    setToken(token);
    setVisitor(token)
  };

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={(e) => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setVisitor: PropTypes.func.isRequired,
};
