import { useState } from "react";
// This is a custom token hook.
// Tutorial: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
export default function useToken() {
  
  const getAltPage = () => {
    const tokenString = localStorage.getItem("visitor_token");
    const userToken = JSON.parse(tokenString);
    if (userToken?.visitor) {
      return true;
    }
    return false;
  }
  
  const [visitor, setVisitor] = useState(getAltPage())
  
  const saveVisitor = (userToken) => {
    localStorage.setItem("visitor_token", JSON.stringify(userToken));
    setVisitor(userToken.visitor);
  };

  return {
    setVisitor: saveVisitor,
    visitor,
  };
}
