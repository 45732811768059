import React, { Component } from "react";
import Video from "../../Video/Video";
import VideoBox from "../../Video/VideoBox";
import "../../../components/CSS/spacing.css";

// eslint-disable-next-line
import SubSeries from "../../SubSeries";

class NoGameNoLife extends Component {
  constructor(props) {
    super(props);
    const baseUrl = "media/Anime/";
    this.load = this.load.bind(this);
    this.state = {
      path: "",
      urls: {
        s1ep1: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E01.mp4",
        s1ep2: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E2.mp4",
        s1ep3: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E3.mp4",
        s1ep4: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E4.mp4",
        s1ep5: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E5.mp4",
        s1ep6: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E6.mp4",
        s1ep7: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E7.mp4",
        s1ep8: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E8.mp4",
        s1ep9: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E9.mp4",
        s1ep10: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E10.mp4",
        s1ep11: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E11.mp4",
        s1ep12: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E12.mp4",
        specialep1:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 01.mp4",
        specialep2:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 02.mp4",
        specialep3:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 03.mp4",
        specialep4:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 04.mp4",
        specialep5:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 05.mp4",
        specialep6:
          baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 06.mp4",
      },
      s1ep1: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E01.mp4",
      s1ep2: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E2.mp4",
      s1ep3: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E3.mp4",
      s1ep4: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E4.mp4",
      s1ep5: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E5.mp4",
      s1ep6: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E6.mp4",
      s1ep7: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E7.mp4",
      s1ep8: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E8.mp4",
      s1ep9: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E9.mp4",
      s1ep10: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E10.mp4",
      s1ep11: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E11.mp4",
      s1ep12: baseUrl + "No Game No Life/Season 1/mp4/No Game No Life - S01E12.mp4",
      specialep1:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 01.mp4",
      specialep2:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 02.mp4",
      specialep3:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 03.mp4",
      specialep4:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 04.mp4",
      specialep5:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 05.mp4",
      specialep6:
        baseUrl + "No Game No Life/Specials/mp4/No Game No Life Special - 06.mp4",
    };
  }

  load(url) {
    this.setState({ path: url });
  }

  render() {
    console.log("Path: " + this.state.path);
    return (
      <div>
        <VideoBox className="videoBox">
          <Video
            path={this.state.path}
            state={this.state}
            urls={this.state.urls}
          />
          {/* <SubSeries seriesLength="12" load={this.load}></SubSeries> */}
          <b>Season 1</b>
          <br />
          <button onClick={() => this.load(this.state.s1ep1)}>Episode 1</button>
          <button onClick={() => this.load(this.state.s1ep2)}>Episode 2</button>
          <button onClick={() => this.load(this.state.s1ep3)}>Episode 3</button>
          <button onClick={() => this.load(this.state.s1ep4)}>Episode 4</button>
          <button onClick={() => this.load(this.state.s1ep5)}>Episode 5</button>
          <button onClick={() => this.load(this.state.s1ep6)}>Episode 6</button>
          <button onClick={() => this.load(this.state.s1ep7)}>Episode 7</button>
          <button onClick={() => this.load(this.state.s1ep8)}>Episode 8</button>
          <button onClick={() => this.load(this.state.s1ep9)}>Episode 9</button>
          <button onClick={() => this.load(this.state.s1ep10)}>
            Episode 10
          </button>
          <button onClick={() => this.load(this.state.s1ep11)}>
            Episode 11
          </button>
          <button onClick={() => this.load(this.state.s1ep12)}>
            Episode 12
          </button>
          <br />
          <b>Specials</b>
          <br />
          <button onClick={() => this.load(this.state.specialep1)}>
            Episode 1
          </button>
          <button onClick={() => this.load(this.state.specialep2)}>
            Episode 2
          </button>
          <button onClick={() => this.load(this.state.specialep3)}>
            Episode 3
          </button>
          <button onClick={() => this.load(this.state.specialep4)}>
            Episode 4
          </button>
          <button onClick={() => this.load(this.state.specialep5)}>
            Episode 5
          </button>
          <button onClick={() => this.load(this.state.specialep6)}>
            Episode 6
          </button>
        </VideoBox>
      </div>
    );
  }
}

export default NoGameNoLife;
